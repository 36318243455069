<template>
  <main class="page-points page-pick-point p-0 d-flex flex-column">
    <div class="nav-container">
      <div class="container">
        <ul class="nav nav-pills nav-fill">
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'points.show' }">Dati</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'points.edit' }">Modifica</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'points.pick' }">Punto</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link active" :to="{ name: 'points.contours' }" aria-current="page">Contorni</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'points.notes' }">Contenuti</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div ref='page' class="flex-grow-1 container-fluid overflow-hidden pt-3 px-0 d-flex flex-column">
      <canvas ref='can'></canvas>
      <div class="d-flex p-3 justify-content-center bg-white" style="z-index: 1; border-top: 1px solid black">
        <template v-if="null === mode">
          <button class="btn btn-primary mx-2" @click.prevent="pickMode('add')">Modalità Aggiungi</button>
          <button class="btn btn-primary mx-2" @click.prevent="pickMode('edit')">Modalità Modifica</button>
          <button class="btn btn-primary mx-2" @click.prevent="pickMode('delete')">Modalità Elimina</button>
          <button class="btn btn-success mx-2" @click.prevent="saveEditorData">Salva</button>
        </template>
        <template v-else>
          <p class="h2 mb-0 mr-4">
            <template v-if="mode === 'add'">Modalità Aggiungi</template>
            <template v-else-if="mode === 'edit'">Modalità Modifica</template>
            <template v-else-if="mode === 'delete'">Modalità Elimina</template>
          </p>
          <button class="btn btn-danger" @click.prevent="exitMode">Esci dalla modalità</button>
        </template>
      </div>
    </div>
  </main>
</template>

<script>
import { visea } from '@/libs/Editor/visea';
import $api from '@/libs/OAuth2/services/api';
import validateMixin from '@/libs/Form/mixins/validate';

export default {
  name: 'module-edit',
  mixins: [validateMixin],
  components: {
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
    module: {
      type: Object,
      required: true,
    },
    point: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      feedback: null,
      mode: null,
      pointData: {
        contours: null,
      },
      visea: visea,
    };
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.can.width = this.$refs.page.clientWidth;
      this.$refs.can.height = this.$refs.page.clientHeight;
      visea.internals.editorMode = visea.EDITOR_MULTIMEDIA;
      visea.internals.useRender = false;
      window.$visea = visea;
      window.$vm = this;
      visea.initCanvas(this.$refs.can);
      // console.log(this.projectData);
      visea.util.addLayer(0, '#FF0000');
      visea.loadImg(this.project?.image?.url);
      visea.util.importPolygons(this.pointData.contours);
      visea.callbacks.hook();
    });
  },
  methods: {
    removeFieldError (field) {
      this.removeFeedback();
      this.removeError(field);
    },
    removeFeedback () {
      this.feedback = null;
    },
    isValid () {
      this.clearErrors();
      this.removeFeedback();

      return !this.hasErrors;
    },
    saveEditorData () {
      this.clearErrors();
      this.removeFeedback();

      if (!this.isValid()) {
        return;
      }

      this.pointData = {
        contours: visea.util.exportPolygons(),
      };

      this.$api.updatePointContours(this.point.id, this.pointData)
        .then(() => {
          this.$router.push({ name: 'points.show' });
        })
        .catch(this.$log.error)
      ;
    },
    pickMode (mode) {
      if (this.mode !== null) {
        return;
      }

      this.mode = mode;
      if (mode === 'add') {
        this.visea.util.switchMode(visea.NEW_MODE);
        return;
      }

      if (mode === 'edit') {
        this.visea.util.switchMode(visea.EDIT_MODE);
        return;
      }

      if (mode === 'delete') {
        this.visea.util.switchMode(visea.DELETE_MODE);
        return;
      }

      if (mode === 'overlay') {
        this.visea.util.switchMode(visea.ADJ_MODE);
      }
    },
    exitMode () {
      visea.util.switchMode(visea.NORMAL_MODE);
      this.mode = null;
    },
  },
  beforeRouteEnter (to, from, next) {
    const id = to.params?.pointId;
    if (!id) {
      return next(from);
    }

    $api.fetchPointContours(id)
      .then(res => res.data)
      .then(data => {
        if (!data) {
          return next(from);
        }

        next(vm => {
          vm.pointData = data;
        });
      })
      .catch(() => next(from))
    ;
  },
};

</script>
